import React from "react";
import { Link } from "react-router-dom";

import '../styles/header.css';
import logoImg from "../assets/icefloating.png"

function Header() {
   return (
      <div className="nav-wrapper">
         <div className="nav-container">
            <Link to="/">
               <div className="nav-logo">
                  <div className="logo-text">
                     氷<span>ばん</span>
                  </div>
                  <div className="logo-img">
                     <img src={logoImg} alt="" />
                  </div>
               </div>
            </Link>
            <Link to="/">
               <div className="nav-txt">
                  Hyouban
               </div>
            </Link>
            <nav className="nav-items" id="js-nav">
               <ul>
                  <Link to="/about">
                     <li>About us</li>
                  </Link>
                  <Link to="/contact">
                     <li>Contact</li>
                  </Link>
               </ul>
            </nav>
            <button className="hamburger" id="js-hamburger">
               <span></span>
               <span></span>
               <span></span>
            </button>
         </div>
      </div>
   )
}

export default Header;