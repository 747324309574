import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { db_fs } from '../../firebase/firebase';
import Review from "../Review";

import '../../styles/mainpage.css';

function MainPage() {
   const nItems = 10;
   const [corps, setCorps] = useState([]);
   const [reviews, setReviews] = useState([]);
   const [searchWord, setSW] = useState("");
   const [searchResults, setSR] = useState([]);

   //get whole corp data for search, and recent review data to show.
   useEffect(() => {
      //corp data
      async function getCorpData() {
         const response = db_fs.collection("corp");
         const data = await response.get();
         data.docs.forEach(item => {
            const { corpname, keyword } = item.data();
            const docID = item.id;
            //use call back when setting state array in useEffect
            setCorps((corps) => [...corps, { corpname, keyword, docID }]);
         })
      }

      //review data
      async function getReviewData() {
         const response = db_fs.collection("review").orderBy('createdOn', 'desc').limit(nItems);
         const data = await response.get();
         data.docs.forEach(item => {
            setReviews((reviews) => [...reviews, item.data()]);
         })
      }

      getCorpData();
      getReviewData();

      return () => {
         setCorps([]);
         setReviews([]);
      };
   }, [])

   //get
   useEffect(() => {
      async function searchCorp() {
         const schw = searchWord.replace(/\s/g, "").toLowerCase(); //spaces igonred 
         corps.forEach((corp) => {
            let kw = corp.keyword.toLowerCase();
            if (schw !== "," && schw !== "" && kw.includes(schw)) {
               const { corpname, docID } = corp;
               //use call back when setting state array in useEffect
               setSR((searchResults) => [...searchResults, { corpname, docID }]);
            }
         })
      }

      //wait typing done(about 1.0sec)
      // Function launches after 1.0 seconds (1000 ms) of the last keystroke
      // On first render you don't want to launch anything
      // Thus, you check if the user typed a query at first
      let timer = setTimeout(() => {
         if (searchWord) { searchCorp() };
      }, 1000);

      // If useEffect() relaunches, you clear the function
      // That means, the previous function won't launch
      // Thus, won't send a request to the API
      return () => {
         setSR([]);
         clearTimeout(timer);

      };
   }, [searchWord])

   return (
      <>
         <div className="mainsec">
            <div className="main-txt">
               <h1>会社の評判を、手軽に。</h1>
            </div>
            <div className="srchbox">
               <div className="schlabel">
                  <h2>会社名で検索</h2>
               </div>
               <input onChange={e => setSW(e.target.value)} type="text"></input>
            </div>
         </div >
         <div className='reviews-wrapper'>
            <div className="reviews">
               <div className="reviews-title">
                  <h2>Recent Reviews</h2>
               </div>
               {
                  reviews && reviews.map((result, index) => {
                     return (
                        <Review data={result} key={index} />
                     )
                  })
               }
            </div>
         </div>
         {searchResults.length > 0 ?
            <div className="sch-results">
               {
                  searchResults && searchResults.map((result, index) => {
                     return (
                        <div className="sch-result" key={index}>
                           <Link to={`/corp/${result.docID}`} >
                              {result.corpname}
                           </Link>
                        </div>
                     )
                  })
               }
            </div>
            : <></>
         }
      </>
   )
}
export default MainPage;