import React, { useState, useEffect } from "react";

import { signInWithGoogle, signOutWithGoogle } from "../../firebase/auth";
import { db_fs, auth } from "../../firebase/firebase";

import '../../styles/admin.css';


function AdminPage() {
   //initialState
   const [authed, setAuth] = useState(false);
   const [displayName, setDN] = useState("");
   const [corpname, setCorpname] = useState("");
   const [enname, setEnname] = useState("");
   const [keyword, setKeyword] = useState("");

   //Sign in
   async function handleSignIn(e) {
      e.preventDefault();
      try {
         const data = await signInWithGoogle();
         //if (data.user) {
         if (data.user.email === 'gutiriman3@gmail.com') {
            setAuth(true);
            setDN(data.displayName);
         } else {
            alert('incorrect user');
         }
      } catch (error) {
         alert(error.message);
      }
   }

   //Sign out
   async function handleSignOut() {
      try {
         await signOutWithGoogle();
         setAuth(false);
         setDN("");
      } catch (error) {
         alert(error.message);
      }
   }

   //Add CorpName
   async function handleAddCorp(e) {
      e.preventDefault();
      const areYouSure = window.confirm("この内容で作成してよろしいでしょうか");
      if (areYouSure) {
         const reviews = [];

         try {
            await db_fs.collection('corp').add({
               corpname,
               enname,
               keyword,
               reviews
            });
         } catch (error) {
            alert(error.message);
         }
      }
   }

   //check if is logged in as correct user
   useEffect(() => {
      const unlisten = auth().onAuthStateChanged(user => {
         if (user && user.email === "gutiriman3@gmail.com") {
            setAuth(true);
            setDN(user.displayName);
         }
      })

      return () => {
         unlisten();
      }
   }, [])

   return (
      <div className="adminpage">

         {authed ?
            <div className="admin-wrapper">
               <div>
                  Logged In As: "{displayName}";
               </div>
               <button onClick={handleSignOut}>Sign Out</button>
               <div className="addCorpForm">
                  <form onSubmit={handleAddCorp}>
                     <div>
                        <label for="name">企業名：</label>
                        <input id="name" onChange={e => setCorpname(e.target.value)} type="text" />
                     </div>
                     <div>
                        <label>English企業名：</label>
                        <input onChange={e => setEnname(e.target.value)} type="text" />
                     </div>
                     <div>
                        <label>キーワード(','で分ける)：</label>
                        <textarea onChange={e => setKeyword(e.target.value)} type="text"></textarea>
                     </div>
                     <button type="submit">ADD</button>
                  </form>
               </div>
            </div>
            :
            <>
               Not Logged In.
               <form onSubmit={handleSignIn}>
                  <button>Sign-in with google</button>
               </form>
            </>
         }
      </div>
   )
}
export default AdminPage;