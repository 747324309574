import React from "react";

import '../../styles/aboutAndContact.css';

function Contact() {
   return (
      <div className="about-wrapper centered">
         <h2>
            <a href="mailto:gutiriman3@gmail.com">&#9656;	Send Email</a>
         </h2>
      </div>
   )
}

export default Contact;