import { auth } from "./firebase";

export function signInWithGoogle() {
   const provider = new auth.GoogleAuthProvider();
   /*
   if select account is needed. 
   -> any google account could login. So this case, 
   should set rules in firestore(DB) for limiting certain google account to access the data.
   */
   provider.setCustomParameters({
      prompt: "select_account"
   });
   return auth().signInWithPopup(provider);
}

export function signOutWithGoogle() {
   return auth().signOut();
}