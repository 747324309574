import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { arrayUnion } from "firebase/firestore";

const firebaseConfig = {
   apiKey: "AIzaSyCPG6KUhmsxXc1oFpxSf1tf5LFbZ4FGMaQ",
   authDomain: "corphyouban.firebaseapp.com",
   projectId: "corphyouban",
   storageBucket: "corphyouban.appspot.com",
   messagingSenderId: "401172897665",
   appId: "1:401172897665:web:c7e10d08bce6df1d495280"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db_fs = firebase.firestore();
export const arrUnion = arrayUnion;