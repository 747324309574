import React from "react";

import '../../styles/aboutAndContact.css';

function AboutUs() {
   return (
      <div className="about-wrapper">
         <h2>Our Mission</h2><br />
         会社の評判を知ること、<br />および会社の評価を行うことが、<br />
         誰でも手軽に行えるように努めます。
      </div>
   )
}

export default AboutUs;