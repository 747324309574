import React from "react";

import '../styles/footer.css';

function Footer() {
   return (
      <div className="footer">© Hyouban All Rights Reserved.</div>
   )
}

export default Footer;