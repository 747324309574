import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

import { db_fs } from "../firebase/firebase";
import { arrUnion } from "../firebase/firebase";

import Review2 from "./Review2";

import '../styles/corppage.css';

import starEmp from '../assets/star_emp.svg';
import starFill from '../assets/star_fill.svg';

function CorpPage() {
   //state of load
   const [corpInfo, setCI] = useState("");
   const [corpReviews, setCRs] = useState([]);
   const { id } = useParams(); //id in URL for search database of corp 
   //state of input
   const [genre, setGenre] = useState("");
   const [dept, setDept] = useState("");
   const [point, setPoint] = useState(0);
   // const [title, setTitle] = useState("");
   const [content, setContent] = useState("");
   const [avePoint, setAve] = useState(0);

   useEffect(() => {
      async function fetchData() {
         //get corp
         const resC = db_fs.collection("corp").doc(id);
         const docC = await resC.get();
         const dataC = docC.data();
         setCI(dataC);
         //get review of corp(Use Promise.all when await for array)
         if (dataC.reviews.length > 0) {
            const resR = db_fs.collection("review")
            const dataR = await Promise.all(dataC.reviews.map(async (reviewID) => {
               return (await resR.doc(reviewID).get()).data();
            }))
            dataR.sort((a, b) => b.createdOn - a.createdOn);//sort latest 
            setCRs(dataR);

            //set average point
            let ave = dataR.reduce(function (sum, element) {
               return sum + element.point;
            }, 0);
            ave = ave / dataR.length;
            ave = Math.round(ave * 10) / 10;//round on scale of 0.1
            setAve(ave);
         }
      }
      fetchData();
   }, []);

   function changeRate(e) {
      //get rate
      const rate = Number(e.currentTarget.id.slice(-1));
      //change star
      const stars = document.getElementsByClassName('rate-star');
      for (let i = 1; i <= rate; i++) {//FILL
         let star = stars[i - 1].getElementsByTagName('img');
         star[0].src = starFill;
      }
      for (let i = rate; i < 5; i++) {//EMPTY
         let star = stars[i].getElementsByTagName('img');
         star[0].src = starEmp;
      }
      //set rate
      setPoint(rate);
   }

   async function handleAddReview(e) {
      e.preventDefault();
      if (point === "" || content === "") {
         alert("「点数」と「コメント」は入力が必要です。")
      } else {
         const areYouSure = window.confirm("この内容で評価してよろしいでしょうか（ボタンを押すと評価が反映されます）。");
         if (areYouSure) {
            try {
               //create review
               const added = await db_fs.collection("review").add({ //"add" create document with auto generated new id
                  corpname: corpInfo.corpname,
                  enname: corpInfo.enname,
                  corpId: id,
                  genre,
                  dept,
                  point,
                  // title,
                  content,
                  createdOn: Date.now()
               });
               //add review id
               await db_fs.collection("corp").doc(id).update({
                  reviews: arrUnion(added.id)
               });
               //clear form
               var frm = document.getElementsByClassName('addreview-frm')[0];
               frm.reset();
               const stars = document.getElementsByClassName('rate-star');
               for (let i = 0; i < 5; i++) {//FILL
                  let star = stars[i].getElementsByTagName('img');
                  star[0].src = starEmp;
               }
            } catch (error) {
               alert(error.message);
            }
         }
      }
   }

   return (
      <>
         <div className="cp-wrapper">
            <div className="addreviews">
               <div className="form-title">
                  <h2>{corpInfo.corpname}</h2> を評価する
               </div>
               <form className="addreview-frm" onSubmit={handleAddReview}>
                  <div className="input-field">
                     <label>部署 </label>
                     <input onChange={e => setDept(e.target.value)} type="text" />
                  </div>
                  <div className="input-field">
                     <label>評価軸 </label>
                     <select onChange={e => setGenre(e.target.value)} type="text"  >
                        <option value="指定なし">指定なし</option>
                        <option value="自己成長・やりがい">自己成長・やりがい</option>
                        <option value="将来性">将来性</option>
                        <option value="組織文化">組織文化</option>
                        <option value="給与・待遇">給与・待遇</option>
                     </select>
                  </div>
                  {/* <div>
                  <label>タイトル</label>
                  <input onChange={e => setTitle(e.target.value)} type="text" />
               </div> */}
                  <div className="input-field">
                     <label>点数<div className="must-notion">必須</div></label>
                     {/* <input onChange={e => setPoint(e.target.value)} type="number" min="1" max="5" />（1～5） */}
                     <div className="rate-stars">
                        {(() => {
                           const stars = [];
                           for (let i = 1; i <= 5; i++) {
                              stars.push(
                                 <div key={i} className="rate-star" onClick={changeRate} id={`star-${i}`}>
                                    <img src={starEmp} />
                                 </div>
                              )
                           }
                           return <>{stars}</>
                        })()}
                     </div>
                  </div>
                  <div className="input-field ">
                     <label className="label-long">評価コメント<div className="must-notion">必須</div></label>
                     <textarea onChange={e => setContent(e.target.value)} type="text"></textarea>

                  </div>
                  <button type="submit">評価する</button>
               </form>
            </div >
         </div >
         <div className="reviews-wrapper">
            <div className="reviews">
               <div className="reviews-header">
                  <div className="reviews-title2"><span>
                     <h2>{corpInfo.corpname}</h2></span> の評判</div>
                  <div className="reviews-avepoint">
                     {<span>評価</span>}
                     <div className="avepoint">
                        <img src={starFill} />{avePoint}
                     </div>
                  </div>
               </div>
               <div className="scrll-lap">
                  {corpReviews.length > 0 ?
                     <>
                        {corpReviews.map((review, index) => {
                           return (
                              <Review2 data={review} key={index} />
                           )
                        })}
                     </> :
                     <>
                        レビューがありません。
                     </>
                  }
               </div>
            </div>
         </div>
      </>
   )
}
export default CorpPage;