import React from "react";
import { Routes, Route } from "react-router-dom";

import MainPage from "./components/main/MainPage.jsx"
import AdminPage from "./components/admin/admin.jsx";
import CorpPage from "./components/CorpPage.jsx";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import AboutUs from "./components/main/AboutUs.jsx";
import Contact from "./components/main/Contact.jsx";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/corp/:id" element={<CorpPage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/admin" element={<AdminPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
