import React from "react";
import { Link } from "react-router-dom";

import '../styles/review.css';
import stars1 from '../assets/1stars.png';
import stars2 from '../assets/2stars.png';
import stars3 from '../assets/3stars.png';
import stars4 from '../assets/4stars.png';
import stars5 from '../assets/5stars.png';

function Review(props) {
   const revData = props.data;
   const date = new Date(revData.createdOn);
   const stars = [stars1, stars2, stars3, stars4, stars5];
   const createdOn = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + ', ' + date.getFullYear();
   return (
      <div className="rev-container">
         <div className="rev-cname">
            <h2>
               <Link to={`/corp/${revData.corpId}`} >
                  {revData.corpname}
               </Link>
            </h2>
         </div>
         <div className="review">
            <div className="rev-basic">
               <div className="rev-basic-other">
                  <div className="item rev-dept">
                     <span>部署</span>
                     {revData.dept ?
                        <>{revData.dept}</> :
                        <>指定なし</>
                     }
                  </div>
                  <div className="item rev-genre">
                     <span>評価軸</span>
                     {revData.genre ?
                        <>{revData.genre}</> :
                        <>指定なし</>
                     }
                  </div>
                  <div className="item rev-point">
                     <span>点数</span>
                     <img src={stars[revData.point - 1]} />
                  </div>
               </div>
            </div>

            <div className="item rev-detail">
               <div className="rev-content">
                  <span>コメント</span>
                  {revData.content}
               </div>
               <div className="rev-createdOn">
                  {createdOn}
               </div>
            </div>
         </div>
      </div>
   )
}

export default Review;